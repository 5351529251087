import * as React from 'react';
import Layout from './Layout';

import { CloudinaryContext, Image } from 'cloudinary-react';

import { graphql } from 'gatsby';

// import { 
//     Image
// } from '@mui/material';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import DataService from '../../services/dataService';

import AddIcon from '@mui/icons-material/Add';

import { set, unset } from 'lodash';

import { siteMetadata } from '../../../gatsby-config';
import * as SettingsHelper from '../../helpers/admin/SettingsHelper';

import { useDispatch } from 'react-redux';
import { setUpdatingData } from '../../redux/admin/adminSlice';

import hexToRgba from 'hex-to-rgba';

const Settings = ({ data, pageContext }) => {

    const dispatch = useDispatch();

    const [settings, setSettings] = React.useState(JSON.parse(data.datoCmsWebsite.settings));
    const [redirectSelectRefs, setRedirectSelectRefs] = React.useState();
    const [yesOverlay, setYesOverlay] = React.useState(false);
    const [showImageOverlay, setShowImageOverlay] = React.useState(false);

    const [currentSiteLogo, setCurrentSiteLogo] = React.useState(null);

    const updateSettings = (value, pointerString, mode = null) => {
        let cSettings = settings;

        switch(mode) {
            case 'delete':
                
                // delete setting by path
                cSettings = settings;
                unset(cSettings, pointerString);
                break;

            default:

                // update setting ready for when the user wants to save thier changes
                cSettings = settings;
                set(cSettings, pointerString, value);
                break;
        }   

        // set settiings state and any other state where convienent
        console.log(cSettings);
        setSettings({ ...cSettings });
    };

    const saveSettings = () => {

        dispatch(setUpdatingData({ updatingData: true }));

        DataService.updateRecord(data.datoCmsWebsite.id.split('-')[1], {
            settings: JSON.stringify(settings)
        }).then( site => {
            setSettings(JSON.parse(site.settings));
            dispatch(setUpdatingData({ updatingData: false }));
        })
    }

    // const updateImage = (newImageId) => {
    //     DataService.getRecord(newImageId)
    //         .then( image => {
    //             getPost();
    //         })
    // }

    const handleSetYesOverlay = (value) => {
        setYesOverlay(value)
    }

    const handleSetCurrentSiteLogo = (publicId) => {
        setCurrentSiteLogo(publicId);
    }

    return (
        <Layout>
            <h2 className='text-center text-2xl font-semibold' style={{ color: '#45425a' }}>Settings</h2>
            <div style={{ backgroundColor: 'whitesmoke', height: '100%', paddingTop: 40 }}>
                <Button onClick={(e) => saveSettings() } variant="outlined" style={{ position: 'absolute', right: 40, top: 20 }}>Save</Button>
                <div 
                    className="grid grid-cols-1 md:grid-cols-2"
                    style={{
                        // display: 'flex',
                        // flexDirection: 'row',
                        // flexWrap: 'wrap'
                    }}
                >
                    <Paper style={{ padding: 40, position: 'relative', borderRadius: 5, margin: 5, flex: 1 }}>
                        <div
                            style={{ 
                                backgroundColor: '#45425a',
                                width: '100%',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                borderTopLeftRadius: 5,
                                borderTopRightRadius: 5,
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <p style={{ color: 'white' }}>
                                Global Settings
                            </p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 40 }}>
                        <div 
                                    style={{
                                        width: '100%',
                                        height: 200,
                                        backgroundColor: hexToRgba('#45425a', 0.5),
                                        position: 'relative',
                                    }}
                                    
                                    onMouseOver={() => {
                                        setShowImageOverlay(true) 
                                    }}
                                    onMouseLeave={() => {
                                        setShowImageOverlay(false)
                                    }}
                                    onClick={() => setYesOverlay(true) }
                                >   
                                    <CloudinaryContext cloudName="dbs3cwu82"
                                        style={{
                                            display: 'flex',
                                            height: '100%',
                                            width: '100%'
                                        }}
                                    >
                                        <Image 
                                            style={{
                                                margin: 'auto',
                                                backgroundColor: 'red',
                                                height: '100%'
                                            }}
                                            publicId={currentSiteLogo !== null ? currentSiteLogo : (settings?.siteLogo?.name !== null ? settings?.siteLogo?.name : '')}
                                        />
                                        {
                                            showImageOverlay ? (
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        height: '100%',
                                                        width: '100%',
                                                        backgroundColor: hexToRgba('#45425a', 0.7)
                                                    }}
                                                >
                                                    <div 
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            height: '100%',
                                                            color: 'whitesmoke'
                                                        }}
                                                    >
                                                        <AddIcon 
                                                            style={{ 
                                                                alignSelf: 'center'
                                                            }} 
                                                        />
                                                        <p style={{ 
                                                            fontSize: 20, 
                                                            fontfamily: 'monospace' 
                                                        }}>Change Image</p>
                                                    </div>
                                                </div>
                                            )
                                            :
                                            (
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        height: '100%',
                                                        width: '100%',
                                                        backgroundColor: hexToRgba('#45425a', settings?.siteLogo ? 0.2 : 0.1)
                                                    }}
                                                >
                                                    {settings?.siteLogo && (
                                                        <div 
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                height: '100%',
                                                                color: 'whitesmoke'
                                                            }}
                                                        >
                                                            <p style={{ 
                                                                fontSize: 20, 
                                                                fontfamily: 'monospace' 
                                                            }}>Site Icon</p>
                                                        </div>
                                                    )}
                                                </div>
                                            )
                                        }
                                    </CloudinaryContext>
                                </div>
                            {
                                yesOverlay && (
                                    <SettingsHelper.Wazap 
                                        handleSetYesOverlay={handleSetYesOverlay} 
                                        settingName="Logo"
                                        siteId={data.datoCmsWebsite.id}
                                        pointerString="siteLogo"
                                        handleSetCurrentSiteLogo={handleSetCurrentSiteLogo}
                                        updateSettings={updateSettings}
                                        saveSettings={saveSettings}
                                    />
                                )
                            }


                            <SettingsHelper.TextInput 
                                key={1}
                                settings={settings}
                                settingName="Site Title"
                                pointerString="siteTitle"
                                updateSettings={updateSettings}
                            />
                            <SettingsHelper.TextInput
                                key={2} 
                                settings={settings}
                                settingName="Tagline"
                                pointerString="siteTagline"
                                updateSettings={updateSettings}
                            />
                            <SettingsHelper.TextInput 
                                key={3}
                                settings={settings}
                                settingName="Contact Email"
                                pointerString="contactEmail"
                                updateSettings={updateSettings}
                            />
                            <SettingsHelper.TextInput 
                                key={4}
                                settings={settings}
                                settingName="Site Description"
                                pointerString="siteDescription"
                                updateSettings={updateSettings}
                            />
                            <SettingsHelper.SocialMediaEditor 
                                key={5}
                                settings={settings}
                                pointerString="social"
                                updateSettings={updateSettings}
                            />

                        </div>  
                    </Paper>

                    <Paper style={{ padding: 40, position: 'relative', borderRadius: 5, margin: 5, flex: 1 }}>
                        <div
                            style={{ 
                                backgroundColor: '#45425a',
                                width: '100%',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                borderTopLeftRadius: 5,
                                borderTopRightRadius: 5,
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <p style={{ color: 'white' }}>
                                Theme Settings
                            </p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 40 }}>
                            <div>
                                <p key={1}>
                                    Theme Colors
                                </p>
                                
                                <div key={2} className='grid grid-cols-1 md:grid-cols-3'>
                                    <SettingsHelper.ColourInput 
                                        key={1}
                                        settings={settings}
                                        settingName="Primary"
                                        settingValue={settings?.colors?.primary}
                                        pointerString={'colors.primary'}
                                        updateSettings={updateSettings}
                                    />
                                    <SettingsHelper.ColourInput 
                                        key={2}
                                        settings={settings}
                                        settingName="Secondary"
                                        settingValue={settings?.colors?.secondary}
                                        pointerString={'colors.secondary'}
                                        updateSettings={updateSettings}
                                    />
                                    <SettingsHelper.ColourInput 
                                        key={3}
                                        settings={settings}
                                        settingName="Tertiary"
                                        settingValue={settings?.colors?.tertiary}
                                        pointerString={'colors.tertiary'}
                                        updateSettings={updateSettings}
                                    />
                                </div>
                                <div className='grid grid-cols-2 mt-4'>
                                    <SettingsHelper.ColourInput 
                                        key={4}
                                        settings={settings}
                                        settingName="Fourth"
                                        settingValue={settings?.colors?.fourth}
                                        pointerString={'colors.fourth'}
                                        updateSettings={updateSettings}
                                    />
                                    <SettingsHelper.ColourInput 
                                        key={5}
                                        settings={settings}
                                        settingName="Fifth"
                                        settingValue={settings?.colors?.fifth}
                                        pointerString={'colors.fifth'}
                                        updateSettings={updateSettings}
                                    />
                                </div>
                            </div>
                            {/* <div>
                                <p>
                                    Font
                                </p>
                                <TextField 
                                    placeholder="Site Title" 
                                    varient="filled" 
                                    defaultValue={settings?.font} 
                                    onChange={(e) => {

                                        updateSettings(e, 'title');
                                    }}
                                />
                            </div> */}

                            <SettingsHelper.SelectInput
                                settings={settings}
                                settingName="Default Font"
                                settingValue={settings?.defaultFont}
                                pointerString="defaultFont"
                                options={siteMetadata.fontOptions}
                                updateSettings={updateSettings}
                            />
                        </div>  
                    </Paper>
                </div>
                <div>
                    <Paper style={{ padding: 40, position: 'relative', borderRadius: 5, margin: 5, flex: 1 }}>
                        <div
                            style={{ 
                                backgroundColor: '#45425a',
                                width: '100%',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                borderTopLeftRadius: 5,
                                borderTopRightRadius: 5,
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <p style={{ color: 'white' }}>
                                Network settings
                            </p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 40 }}>
                            <div style={{ display: 'flex' }}>
                                <p style={{ marginRight: 40 }}>
                                    Primary Site Domain
                                </p>
                                <TextField 
                                    placeholder="Site Title" 
                                    varient="standard"
                                    defaultValue={settings?.domainName}
                                    onChange={(e) => {

                                        updateSettings(e.target.value, 'domainName');
                                    }}
                                />
                            </div>
                            <div>
                                <p>Redirects</p>
                                
                            </div>
                        </div>
                    </Paper>
                </div>

            </div>
        </Layout>
    );
}

export const query = graphql`
    query siteQuery($sitename: String) {
        datoCmsWebsite(name: {eq: $sitename}) {
            id 
            name 
            settings
        }
    }
`

export default Settings;