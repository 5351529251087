import * as React from 'react';
import { siteMetadata } from '../../../gatsby-config';

import { get, isArray } from 'lodash';

import { useDispatch } from 'react-redux';

import {
    TextField
} from '@mui/material';

import { SketchPicker } from 'react-color';
import {
    FormLabel,
    Select,
    MenuItem,
    Icon,
    IconButton
} from '@mui/material';

import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PinterestIcon from '@mui/icons-material/Pinterest';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

import { CloudinaryContext, Image } from 'cloudinary-react';

import DataService from '../../services/dataService';
import { setUpdatingData } from '../../redux/admin/adminSlice';



const ColourInput = ({ settings, settingName, pointerString, updateSettings }) => {

    const dispatch = useDispatch();

    const [settingValue, setSettingValue] = React.useState(null);
    const [showSelector, setShowSelector] = React.useState(false);

    const [selectedColour, setSelectedColour] = React.useState('')
    const [openColorSelect, setOpenColourSelect] = React.useState(false);

    React.useEffect(() => {

        setSelectedColour(get(settings, pointerString));
    }, []);

    return (
        <div style={{ 
            display: 'flex', 
            flexDirection: 'column', 
            marginRight: 30,
            backgroundColor: '#45425a',
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: 'black',
            borderRadius: 3,
            padding: 20
        }}>
            <FormLabel style={{ float: 'left', padding: 5, textAlign: 'center', color: 'whitesmoke' }}>{settingName}</FormLabel>
            <div 
                style={{ 
                    width: 100, 
                    height: 100, 
                    borderRadius: 50, 
                    backgroundColor: selectedColour, 
                    alignSelf: 'center', 
                    borderStyle: 'solid',
                    borderWidth: 1,
                    borderColor: 'whitesmoke' 
                }} 
                onClick={(e) => {
                    setOpenColourSelect(true) 
                    // dispatch(setSectionsDragDisabled({ sectionsDragDisabled: true }))
                }}    
            />
        
            {
                openColorSelect && (
                    <div 
                        draggable={true}
                        id="overlayBackground"
                        style={{ 
                            height: '100%', 
                            width: '100%',  
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: 'rgba(0,0,0,0.2)',
                            // backgroundColor: 'red',
                            position: 'fixed',
                            zIndex: 2,
                            top: 0,
                            left: 0
                        }}
                        onClick={(e) => {
                            const background = document.getElementById('overlayBackground');
                            if (background === e.target) {
                                setOpenColourSelect(false);
                                // dispatch(setSectionsDragDisabled({ sectionsDragDisabled: false }));
                            }
                        }}
                        onDragStart={event => event.preventDefault() }
                    >
                        <div 
                            style={{ 
                                backgroundColor: 'whitesmoke',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-evenly',
                                alignContent: 'center',
                                width: '30%',
                                height: '45%',
                                alignItems: 'center',
                                borderRadius: 5,
                                borderStyle: 'solid',
                                borderWidth: 1,
                                borderColor: 'grey',
                                position: 'relative',
                                zIndex: 3
                            }}
                            onClick={() => {
                                setOpenColourSelect(true);
                            }}
                        >
                            <h4 style={{ fontFamily: 'monospace', fontSize: 30, margin: 5 }}>Change Color</h4>
                            <div>
                                <SketchPicker 
                                    color={selectedColour}
                                    onChangeComplete={(colour, event) => {
                                        setSelectedColour(colour.hex);
                                        updateSettings(colour.hex, pointerString);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

const TextInput = ({ settings, settingName, pointerString, updateSettings, muted, themeColors, showTitle = true }) => {

    const [selectedFont, setSelectedFont] = React.useState(null);
    const [selectedColor, setSelectedColor] = React.useState('');
    const [selectedFontSize, setSelectedFontSize] = React.useState(null);
    const [settingValue, setSettingValue] = React.useState(null);

    React.useEffect(() => {

        setSettingValue(get(settings, pointerString));
    }, []);


    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                marginRight: 30
            }}
        >
            {showTitle && (
                <FormLabel style={{ float: 'left', padding: 5, color: 'black' }}>{settingName}</FormLabel>
            )}
            <TextField
                placeholder={settingName}
                defaultValue={get(settings, `${pointerString}.value`)}
                onChange={(e) => {
                    setSettingValue(e.target.value);
                    updateSettings(e.target.value, `${pointerString}.value`);
                    console.log(muted);
                }}
                style={{ position: 'relative' }}
                inputProps={{ style: { fontFamily: selectedFont, color: selectedColor } }}
            />
        </div>
    )
}


const SelectInput = ({ settings, settingName, settingValue, pointerString, options, updateSettings }) => {

    const [menuOptions, setMenuOptions] = React.useState([]);
    const [menuOptionValues, setMenuOptionValues] = React.useState([]);
    const [chosenOption, setChosenOption] = React.useState(null);


    React.useEffect(() => {

        if (!isArray(options)) {
            if (Object.keys(options).length) {
                setMenuOptions(Object.keys(options))
                setMenuOptionValues(Object.values(options))
            } 
        } else {
            
            setMenuOptions(options);
            setMenuOptionValues(options);
        }
        
    }, []);

    const handleSetChosenOption = (option) => {

        if (option == "Select " + settingName) return

        updateSettings(option, pointerString)
        setChosenOption(option);
    }

    return (
        <div className='flex flex-col flex-start mr-8'>
            <label className='p-1'>{settingName}</label>
            <Select
                id={pointerString}
                defaultValue={"Select " + settingName}
                value={chosenOption !== null ? chosenOption : settingValue}
                onChange={(e) => handleSetChosenOption(e.target.value) }
            >
                <MenuItem key={0} value={"Select " + settingName}>
                    {"Select " + settingName}
                </MenuItem>
                {menuOptions.map( (option, index) => (
                    <MenuItem key={index + 1} value={menuOptionValues[index]}>
                        {option}
                    </MenuItem>
                ))}
            </Select>
        </div>
    )
}

const ChooseImage = ({ settings, settingName, pointerString, updateSettings, saveSettings, muted }) => {

    const [showImagePicker, setShowImagePicker] = React.useState(false);
    const [imageUrl, setImageUrl] = React.useState(get(settings, pointerString));
    const [showEditIcon, setShowEditIcon] = React.useState(false);

    const containerRef = React.useRef(null);

    React.useLayoutEffect(() => {
        console.log(containerRef.current.get)
    })

    const handleShowOverlay = (value) => {
        console.log(showImagePicker)
        setShowImagePicker(value)
    }

    const handleChangeImage = (publicId) => {
        updateSettings(publicId, pointerString);
        setImageUrl(publicId);
        setShowImagePicker(false);
    }

    return (
            <CloudinaryContext 
                cloudName="dbs3cwu82" 
                style={{
                    position: 'relative',
                    height: '4rem',
                    display: 'flex',
                    justifyContent: 'center'
                }}
                ref={containerRef}
                onMouseOver={() => setShowEditIcon(true) }
                onMouseLeave={() => setShowEditIcon(false) }
            >
                <Image 
                    publicId={imageUrl}
                    width={200}
                    style={{
                        position: 'absolute',
                        top: '-50%',
                        transform: 'translate(0, 50%)'
                    }}
                />
            
            {
                showEditIcon && (
                    <IconButton
                        onClick={() => setShowImagePicker(true)}
                        style={{
                            alignSelf: 'center',
                        }}
                    >
                        <AddIcon
                            style={{ 
                                alignSelf: 'center',
                                width: 30,
                                height: 30,
                                zIndex: 2,
                                backgroundColor: 'black',
                                color: 'white',
                                borderRadius: 15,
                                borderColor: 'white',
                                borderWidth: 1,
                                borderStyle: 'solid'
                            }} 
                        />
                    </IconButton>
                )
            }
                {
                    showImagePicker && (
                        <ImagePickerOverlay
                            handleShowOverlay={handleShowOverlay}
                            updateSettings={updateSettings}
                            pointerString={pointerString}
                            handleChangeImage={handleChangeImage}
                        />
                    )
                }
            </CloudinaryContext>
    )
}

const ImagePickerOverlay = ({ handleShowOverlay, siteId, pointerString, updateImage, updateSettings, handleChangeImage }) => {

    const [images, setImages] = React.useState([]);

    React.useEffect(() => {

        DataService.getAllRecords(siteMetadata.datoCmsModelIds.Image, siteId)
            .then( images => {
                setImages(images);
            })
    }, []);

    return (
        <div
            style={{
                display: 'flex',
                position: 'fixed',
                height: '100vh', 
                width: '100vw',
                backgroundColor: 'rgb(0,0,0,0.7)', 
                justifyContent: 'center',
                alignItems: 'center',
                left: 0,
                top: 0,
                zIndex: 2,
            }}
        >
            <div style={{ 
                width: '75%',
                height: '75%',
                backgroundColor: 'whitesmoke',
                borderRadius: 20,
                zIndex: 3,
                opacity: 1,
                paddingLeft: '1rem',
                paddingRight: '1rem',
                fontFamily: 'monospace',
                position: 'relative',
                padding: '1rem',
                paddingTop: '2rem',
                overflowY: 'scroll'
            }}>
                <IconButton style={{ position: 'fixed', right: 20, top: 20, zIndex: 5 }} onClick={() => handleShowOverlay(false) } title="close">
                    <CloseIcon style={{ 
                        color: 'whitesmoke'
                    }} />
                </IconButton>
                
                <CloudinaryContext 
                    cloudName="dbs3cwu82"
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap'
                    }}
                >
                    <h1 style={{ 
                        paddingLeft: '0.5rem', 
                        fontFamily: 'monospace',
                        width: '100%' 
                    }}>Select Image</h1>
                {
                    images.map( image => (
                        <div
                            style={{
                                position: 'relative'
                            }}
                        >
                            {/* <Image 
                                publicId={image.name}
                                width={200}
                                style={{ 
                                    margin: 10
                                }}
                            /> */}
                            <ImagePickerImage
                                publicId={image.name}
                                imageId={image.id}
                                // selectImage={selectImage}
                                updateImage={updateImage}
                                updateSettings={updateSettings}
                                pointerString={pointerString}
                                handleChangeImage={handleChangeImage}
                            />
                        </div>
                    ))
                }
                </CloudinaryContext>
            </div>
        </div>
    )
}

const ImagePickerImage = ({ publicId, imageId, updateSettings, pointerString, handleChangeImage }) => {

    const [mouseOver, setMouseOver] = React.useState(false);

    const imageRef = React.createRef();


    return (
        <div
            onMouseOver={() => setMouseOver(true) }
            onMouseLeave={() => setMouseOver(false) }
            style={{ 
                position: 'relative', 
                height: 'fit-content',
                width: 'fit-content', 
                top: 10,
                margin: 10
            }}
        >
            {
                mouseOver && (
                    <div style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(62, 62, 62, 0.8)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <IconButton
                            style={{
                                borderWidth: 1,
                                borderStyle: 'solid',
                                borderColor: 'whitesmoke',
                                height: 'fit-content',
                                alignSelf: 'center',
                                margin: 'auto'
                            }}
                            onClick={() => handleChangeImage(publicId) }
                        >
                            <AddIcon 
                                style={{
                                    color: 'whitesmoke'
                                }}
                                size={20}
                            />
                        </IconButton>
                    </div>
                )
            }
            <Image
                ref={imageRef}
                publicId={publicId} 
                width={200}
                style={{
                    margin: 10
                }} 
            />
        </div>
    )
}

const CustomImage = ({ publicId, imageId, selectImage, updateImage }) => {

    const [mouseOver, setMouseOver] = React.useState(false);

    const imageRef = React.createRef();

    React.useEffect(() => {
        console.log(imageRef.current)
    }, []);

    React.useEffect(() => {
        console.log(mouseOver);
    }, [mouseOver])

    return (
        <div
            onMouseOver={() => setMouseOver(true) }
            onMouseLeave={() => setMouseOver(false) }
            style={{ 
                position: 'relative', 
                height: 'fit-content',
                width: 'fit-content', 
                top: 10,
                margin: 10
            }}
        >
            {
                mouseOver && (
                    <div style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(62, 62, 62, 0.8)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <IconButton
                            style={{
                                borderWidth: 1,
                                borderStyle: 'solid',
                                borderColor: 'whitesmoke',
                                height: 'fit-content',
                                alignSelf: 'center',
                                margin: 'auto'
                            }}
                            onClick={() => selectImage(imageId) }
                        >
                            <AddIcon 
                                style={{
                                    color: 'whitesmoke'
                                }}
                                size={20}
                            />
                        </IconButton>
                    </div>
                )
            }
            <Image
                ref={imageRef}
                publicId={publicId} 
                width={200}
                style={{
                    margin: 10
                }} 
            />
        </div>
    )
}

const Wazap = ({ handleSetYesOverlay, siteId, pointerString, updateImage, updateSettings, saveSettings, handleSetCurrentSiteLogo }) => {

    const [images, setImages] = React.useState([]);

    const dispatch = useDispatch();    

    const selectImage = (imageId) => {

        dispatch(setUpdatingData({ updatingData: true }));

        DataService.getRecord(imageId)
            .then( image => {
                console.log(image)
                updateSettings(image.id, `${pointerString}.id`);
                updateSettings(image.name, `${pointerString}.name`);
                saveSettings();
                handleSetCurrentSiteLogo(image.name);
            })
        
        dispatch(setUpdatingData({ updatingData: false }));
    
    }

    React.useEffect(() => {

        console.log('siteId: ' + siteId);

        DataService.getAllRecords(siteMetadata.datoCmsModelIds.Image, siteId)
            .then( images => {
                setImages(images);
            })
    }, []);

    return (
        <div
            style={{
                display: 'flex',
                position: 'fixed',
                height: '100vh', 
                width: '100vw',
                backgroundColor: 'rgb(0,0,0,0.7)', 
                justifyContent: 'center',
                alignItems: 'center',
                left: 0,
                top: 0,
                zIndex: 2,
            }}
        >
            <div style={{ 
                width: '75%',
                height: '75%',
                backgroundColor: 'whitesmoke',
                borderRadius: 20,
                zIndex: 3,
                opacity: 1,
                paddingLeft: '1rem',
                paddingRight: '1rem',
                fontFamily: 'monospace',
                position: 'relative',
                padding: '1rem',
                paddingTop: '2rem',
                overflowY: 'scroll'
            }}>
                <IconButton style={{ position: 'fixed', right: 20, top: 20, zIndex: 5 }} onClick={() => handleSetYesOverlay(false) } title="close">
                    <CloseIcon style={{ 
                        color: 'whitesmoke'
                    }} />
                </IconButton>
                
                <CloudinaryContext 
                    cloudName="dbs3cwu82"
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap'
                    }}
                >
                    <h1 style={{ 
                        paddingLeft: '0.5rem', 
                        fontFamily: 'monospace',
                        width: '100%' 
                    }}>Select Image</h1>
                {
                    images.map( image => (
                        <div
                            style={{
                                position: 'relative'
                            }}
                        >
                            <CustomImage 
                                publicId={image.name}
                                imageId={image.id}
                                selectImage={selectImage}
                                updateImage={updateImage}
                            />
                        </div>
                    ))
                }
                </CloudinaryContext>
            </div>
        </div>
    )
}

const SocialMediaEditor = ({ settings, pointerString, updateSettings }) => {

    

    return (
        <div className="flex flex-col mt-10">
            <p className="text-xl">Social Media Links</p>
            <div className="flex m-4 ml-0">
                <TextInput
                    settings={settings}
                    settingName={"Facebook"}
                    pointerString={`${pointerString}.facebook`}
                    updateSettings={updateSettings}
                    showTitle={false}
                />
                <FacebookRoundedIcon style={{ color: '#3b5998' }} className="w-10 self-center" />
            </div>
            <div className="flex m-4 ml-0">
                <TextInput
                    settings={settings}
                    settingName={"Instagram"}
                    pointerString={`${pointerString}.instagram`}
                    updateSettings={updateSettings}
                    showTitle={false}
                />
                <InstagramIcon style={{ color: "#c32aa3" }} className="w-10 self-center" />
            </div>
            <div className="flex m-4 ml-0">
                <TextInput
                    settings={settings}
                    settingName="Twitter"
                    pointerString={`${pointerString}.twitter`}
                    updateSettings={updateSettings}
                    showTitle={false}
                />
                <TwitterIcon style={{ color: "#1da1f2" }} className="w-10 self-center" />
            </div>
            <div className="flex m-4 ml-0">
                <TextInput
                    settings={settings}
                    settingName={"Pinterest"}
                    pointerString={`${pointerString}.pinterest`}
                    updateSettings={updateSettings}
                    showTitle={false}
                />
                <PinterestIcon style={{ color: "#bd081c" }} className="w-10 self-center" />
            </div>
            <div className="flex m-4 ml-0">
                <TextInput
                    settings={settings}
                    settingName={"Linked In"}
                    pointerString={`${pointerString}.linkedIn`}
                    updateSettings={updateSettings}
                    showTitle={false}
                />
                <LinkedInIcon style={{ color: "#0a66c2" }} className="w-10 self-center" />
            </div>
            <div className="flex m-4 ml-0">
                <TextInput
                    settings={settings}
                    settingName={"Youtube Icon"}
                    pointerString={`${pointerString}.youtube`}
                    updateSettings={updateSettings}
                    showTitle={false}
                />
                <YouTubeIcon style={{ color: "#ff0000" }} className="w-10 self-center" />
            </div>
        </div>
    )
}

export {
    ColourInput,
    TextInput,
    SelectInput,
    SocialMediaEditor,
    ChooseImage,
    Wazap
};
